import CryptoForm from './Cryptoform';
import { ChakraProvider, Box } from '@chakra-ui/react';



function App() {

  return (
    <ChakraProvider>
      <Box p={0}> 
        <CryptoForm />
      </Box>
    </ChakraProvider>
  );
}

export default App;
