import React, { useState, useEffect } from "react";
import './form.css'
import axios from "axios";
import Dropdown from "./Dropdown";
import {
  Td,
  Flex,
  Image,
  Button,
  Icon,
  Box,
  Select,
  VStack,
  SimpleGrid,
  IconButton,
  Text,
  HStack
} from "@chakra-ui/react";

// import Swiper core and required modules
import { Navigation, EffectCoverflow, Autoplay, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import {
  createWeb3Modal,
  useDisconnect,
  defaultConfig,
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import { CovalentClient } from "@covalenthq/client-sdk";
import {
  Div3,
  Div6,
  Span5,
  Img5,
  SpanBottom,
  Footer,
} from "./UI/Components";
import { FaTwitter , FaTelegram } from "react-icons/fa";


interface CryptoList {
  uuid: string;
  symbol: string;
  iconUrl: string;
}

interface BalanceItem {
  contract_address: string;
  contract_ticker_symbol: string;
  logo_url: string;
  quote_rate: number;
}

interface TopToken {
  name: string;
  marketCap: string;
  logo: string;
}

interface GainerToken {
  name: string;
  price: string;
  logo: string;
  gainPercent: string
}
interface Banners {
  id: number;
  imagePath: string;
  link: string;
}

// 1. Get projectId
const projectId = 'f8272835172d9401c99bd2bdbd45863c';

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://cloudflare-eth.com",
};

// 3. Create modal
const metadata = {
  name: "Marvin",
  description: "Marvin INU",
  url: "https://marvin-ecosystem.com/",
  icons: [""],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  defaultChain: mainnet,
  projectId,
});

const CryptoForm = () => {
  const [topTokens, setTopTokens] = useState<TopToken[]>([]);
  const [gainerTokens, setGainerTokens] = useState<GainerToken[]>([]);
  const [balance, setBalance] = useState(0);
  const [selectedToken, setSelectedToken] = useState('');
  const [mostCompared, setMostCompared] = useState<Array<{ logo1: string; logo2: string; pair: string; id: number; }>>([]);
  const [coin1, setCoin1] = useState("");
  const [coin2, setCoin2] = useState("");
  const [result, setResult] = useState<string | null>(null);
  const [cryptoList, setCryptoList] = useState<CryptoList[]>([]);
  const [walletTokenList, setWalletTokenList] = useState<BalanceItem[]>([]);
  const [resultXValue, setResultXValue] = useState(0);
  const [bannerData, setBannerData] = useState<Banners[]>([]);
  const [tokenValue, setTokenValue] = useState(0);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { disconnect } = useDisconnect();
  const covalentApiKey = "cqt_rQ3JtFJXvpWDQxdhHJg4WpDdk4wt";
  const moralisKey = process.env.REACT_APP_MORALIS_KEY;
  const coinRankingKey = process.env.REACT_APP_COINRANKING_KEY;

  console.log(covalentApiKey, moralisKey)

  const formatMarketCap = (marketCapString: string) => {
    const marketCap = parseFloat(marketCapString);
    return new Intl.NumberFormat().format(marketCap);
  };

  const fetchCryptoList = async () => {
    try {
      const response = await axios.get(
        "https://api.coinranking.com/v2/coins?limit=100"
      );
      console.debug(response.data.data.coins);
      const coinsArray = response.data.data.coins;
      setCryptoList(coinsArray);
    } catch (error) {
      console.debug("Error Fetching the All Crypto List : ", error);
    }
  };
  useEffect(() => {
    fetchCryptoList();
  }, []);

  useEffect(() => {
    const checkChainId = async () => {
      try {
        if (!isConnected) return; // If not connected, return

        if (chainId !== 1) {
          await disconnect();
          alert(
            "We only support ETH right now, so please connect to ETH Mainnet."
          );
        } else {
          await fetchWalletTokens();
          return;
        }
      } catch (error) {
        console.error("Error checking chain ID:", error);
      }
    };

    checkChainId(); // Initially check the chain ID when the component mounts
  }, [isConnected, chainId]);

  useEffect(() => {
    // Fetch banner details from the external JSON file
    fetch('https://raw.githubusercontent.com/kpvy2002/moon-tools-banners/main/ad-banners.json')
      .then((response) => response.json())
      .then((data) => {
        console.log('Fetched data:', data); // Log the fetched data
        setBannerData(data.banners || []);
      })
      .catch((error) => {
        console.error('Error fetching banner details:', error);
      });
  }, []);


  const fetchWalletTokens = async () => {
    if (!walletProvider || !isConnected) {
      alert("Wallet not Connected");
      return;
    }
    try {
      // Fetch tokens only when isConnected is true and chainId is 1
      console.debug("Fetching ERC20 tokens...");
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      const signer = ethersProvider.getSigner();
      const address = await signer.getAddress();
      const client = new CovalentClient(covalentApiKey);
      const resp = await client.BalanceService.getTokenBalancesForWalletAddress(
        "eth-mainnet",
        address
      );
      console.log(resp);
      if (!resp.error) {
        console.log(resp.data.items);
        setWalletTokenList(resp.data.items);
      } else {
        console.debug(resp.error_message);
      }
    } catch (error) {
      console.error("Error fetching tokens:", error);
    }
  };

  const handleCompare = async () => {
    try {
      // Update most compared tokens on the server
      await axios.post('https://7658-137-184-124-150.ngrok-free.app/api/compare', { token1: coin1, token2: coin2 });
      console.log(coin1, coin2)
      const [response1, response2] = await Promise.all([
        axios.get(
          coin1.length > 15
            ? `https://api.dexscreener.com/latest/dex/tokens/${coin1}`
            : `https://api.coinranking.com/v2/coin/${coin1}`
        ),
        axios.get(`https://api.coinranking.com/v2/coin/${coin2}`, {
          headers: {
            'x-access-token': coinRankingKey,
          },
        }
        ),
      ]);
      console.debug(coin1);
      console.debug(response1, response2);
      const coinCap1 =
        coin1.length > 15
          ? response1.data.pairs[0].fdv
          : response1.data.data.coin.marketCap;
      console.debug(coinCap1);
      const coin2Data = response2.data.data.coin;
      const coinCap2 = coin2Data ? coin2Data.marketCap : null;

      console.debug("Coin 1 Market Cap:", coinCap1);
      console.debug("Coin 2 Market Cap:", coinCap2);
      const ratio = Number(coinCap2) / Number(coinCap1);
      setResult(ratio.toFixed(2));

      console.debug(result);
    } catch (error) {
      console.error("Error fetching data:", error);
      alert(
        "Error Comparing MarketCap. Maybe the Token isn't listed on any Exchanges or A Rugged Token!"
      );
    }
  };


  const handleSelectCoin2 = (value: string) => {
    setCoin2(value);
  }
  const handleSelectCoin1 = (value: string) => {
    setCoin1(value);
  }
  // Function to handle token selection
  const handleTokenSelect = async (value: any) => {
    console.log(value);
    const selectedToken = value;
    // Set the selected token
    setSelectedToken(selectedToken);
    console.log('i m in')
    if (!walletProvider || !isConnected) {
      alert("Wallet not Connected");
      return;
    }
    // Make API request to fetch balance
    try {
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      const signer = ethersProvider.getSigner();
      const address = await signer.getAddress();
      const client = new CovalentClient(covalentApiKey);
      const response = await client.BalanceService.getTokenBalancesForWalletAddress(
        "eth-mainnet",
        address
      );
      const balanceItems = response.data.items
      const matchedItem = balanceItems.find((item: { contract_address: any; }) => item.contract_address === selectedToken);
      console.log(matchedItem);
      if (matchedItem && matchedItem.balance !== null) {
        console.log(matchedItem)
        let tokenBalance = parseFloat((matchedItem.balance).toString()) / 10 ** matchedItem.contract_decimals
        console.log(tokenBalance);
        const tokenQuote = matchedItem.quote_rate;
        let value = tokenQuote * tokenBalance;
        setBalance(tokenBalance);
        setTokenValue(value);
      } else {
        setBalance(0);
      }
      // // Assuming the API returns balance in the response
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };

  const calculateX = (x: number) => {
    let value = tokenValue * x;
    setResultXValue(value);
  }



  useEffect(() => {
    const fetchTopTokens = async () => {
      try {
        const response = await axios.get(
          'https://api.coinranking.com/v2/coins?limit=10'
        );
        console.log(response.data.data)
        const data = response.data.data.coins;
        const formattedTokens: TopToken[] = data.map((token: any) => ({
          name: token.name,
          marketCap: formatMarketCap(token.marketCap),
          logo: token.iconUrl
        }));

        setTopTokens(formattedTokens);
      } catch (error) {
        console.error('Error fetching top tokens:', error);
      }
    };

    const fetchTopGainers = async () => {
      try {
        const response = await axios.get(
          'https://api.coinranking.com/v2/coins?timePeriod=24h&orderBy=change&orderDirection=desc&base=USD&limit=3'
        );
        console.log(response.data.data)
        const data = response.data.data.coins;
        const formattedTokens: GainerToken[] = data.map((token: any) => ({
          name: token.symbol,
          price: token.price,
          logo: token.iconUrl,
          gainPercent: token.change
        }));
        setGainerTokens(formattedTokens);
        console.log(formattedTokens);

      } catch (error) {
        console.error('Error fetching gainer tokens:', error);
      }
    }

    const fetchMostComparedTokens = async () => {
      try {

        const response = await axios.get('https://7658-137-184-124-150.ngrok-free.app/api/mostComparedTokens');
        console.log(response);
        const tokens = response.data;

        const items = [];
        for (let i = 0; i < 5 && i < tokens.length; i++) {
          let name1, name2, logo1, logo2;
          if (tokens[i].token1.length > 15) {
            const res = await axios.get(`https://deep-index.moralis.io/api/v2.2/erc20/metadata?chain=eth&addresses%5B0%5D=${tokens[i].token1}`, {
              headers: {
                'x-api-key': moralisKey
              }
            });
            console.log(res.data[0])
            name1 = res.data[0].symbol;
            if (name1 === 'MARVIN') {
              logo1 = 'https://s2.coinmarketcap.com/static/img/coins/64x64/16537.png';
            } else {
              logo1 = res.data[0].logo;
            }
          } else {
            console.log(tokens[i].token1)
            await new Promise(resolve => setTimeout(resolve, 3000));
            const res = await axios.get(`https://api.coinranking.com/v2/coin/${tokens[i].token1}`, {
              headers: {
                'x-access-token': coinRankingKey,
              },
            });
            console.log(res.data.data.coin)
            name1 = res.data.data.coin.symbol;
            logo1 = res.data.data.coin.iconUrl;
          }

          await new Promise(resolve => setTimeout(resolve, 3000));
          const res2 = await axios.get(`https://api.coinranking.com/v2/coin/${tokens[i].token2}`, {
            headers: {
              'x-access-token': coinRankingKey,
            },
          });
          console.log(res2.data.data.coin)
          name2 = res2.data.data.coin.symbol;
          logo2 = res2.data.data.coin.iconUrl;

          const pair = `${name1}  vs.  ${name2}`;
          const id = i + 1; // IDs start from 1
          console.log(pair, id);
          items.push({ logo1, logo2, pair, id });

        }
        setMostCompared(items);
        console.log(items);
      } catch (error) {
        console.error('Error fetching token pairs:', error);
      }
    }

    fetchMostComparedTokens();
    fetchTopTokens();
    fetchTopGainers();
  }, []);

  const featuredTokens = [
    { label: 'MARVIN', value: 'FbSN83HRa', imageUrl: 'https://cdn.coinranking.com/uoKELb1GM-l/marvin-inu.png' },
    { label: 'BONE', value: 'V2gPy4UsR', imageUrl: 'https://cdn.coinranking.com/hxEh_H3BA/bone_icon.png' },
    { label: 'ELON', value: 'Ac13iS3os', imageUrl: 'https://cdn.coinranking.com/VHc5KxjKj/dogelon-mars.png' },
    { label: 'ladys', value: 'n3ColIIL', imageUrl: 'https://cdn.coinranking.com/XxvsO7Px2/LADYS.png' },
    { label: 'PORK', value: 'nxR0q22CR', imageUrl: 'https://cdn.coinranking.com/3AjxqU6EN/29220.png' },
    { label: 'WEN', value: '7MDx4U0lk', imageUrl: 'https://cdn.coinranking.com/B6ahDisTg/WEN.png' },
    { label: 'TOSHI', value: 'hgqvuYDB', imageUrl: 'https://cdn.coinranking.com/FcmfYr4KL/3UEOfrDE_400x400.PNG' },
    { label: 'AIDOGE', value: '2FZG3zvi1', imageUrl: 'https://cdn.coinranking.com/evsvj9Qzi/WBCMqI0t_400x400.PNG' },
    { label: 'GROK', value: 'b8_Nk0VB6x', imageUrl: 'https://cdn.coinranking.com/dJPtKelWj/GROK.PNG' },
    { label: 'BYTE', value: 'xQmtXKyOi', imageUrl: 'https://cdn.coinranking.com/FOWHi4lv0/byte.png' },
    { label: 'TROLL', value: 'oGxdIo07O', imageUrl: 'https://cdn.coinranking.com/TcldxHH0l/fe1ppQ2b_400x400.png' },
    { label: 'HarryPotterObamaSonic10Inu', value: 'iKrMxeXwC', imageUrl: 'https://cdn.coinranking.com/RhQ0sjTH2/hpos10i_32.PNG' },
    { label: 'KIZUNA', value: 'TSeRBkU4W', imageUrl: 'https://cdn.coinranking.com/yN1JblpxY/S-vIyCQY_400x400.png' }
  ];

  return (
    <>
      <div className="main-container">
        <Box p={2}>
      <Swiper
      spaceBetween={1}
      slidesPerView={'auto'}
      effect={"coverflow"}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: false,
      }}
      centeredSlides={true}
      autoplay={{ delay: 3000 }}
      loop={true}
      modules={[Navigation, EffectCoverflow, Autoplay, Pagination, Scrollbar, A11y]}
      navigation={true}
      grabCursor={true}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSwiper={(swiper : any ) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
    >
      {bannerData.map((banner, index) => (
        <SwiperSlide style={{padding:'10px'}}key={index}>
            <img
              src={banner.imagePath || ''}
              alt={`Banner ${index + 1}`}
              width="100%"
              style={{cursor: 'pointer'}}
              onClick={() => {
                window.open(banner.link || '', '_blank');
              }}
            />
        </SwiperSlide>
      ))}
    </Swiper>
    </Box>
        <VStack marginTop="30px">

          <w3m-button balance="hide" />
          <Div3>MOON TOOLS</Div3>
        </VStack>

        <HStack padding='20px' justifyContent='center' gap='3rem' flexDirection={['column', 'row']}>

          <Box
            backgroundColor='rgba(149, 149, 149, 0.26)'
            padding='20px'
            border='3px solid #5cb0ee'
            borderRadius='30px'
            alignItems='center'
            width={['75%', '20%']}
            marginBottom={['10px', 0]}>

            <Box
              backgroundColor='rgba(149, 149, 149, 0.26)'
              borderRadius='30px'
              display='flex'
              textColor='white'
              justifyContent='center'
              marginBottom='10px'

            ><Text>Most Compared</Text></Box>

            <div className="small-box-table">
              {mostCompared.map((item: { id: any; logo1: any; pair: any; logo2: any; }) => (
                <div key={item.id} className="table-item">
                  <span className="token">
                    <img style={{ marginRight: 'auto' }} src={item.logo1} alt='logo1' />
                    {item.pair}
                    <img style={{ marginLeft: 'auto', marginRight: '0px' }} src={item.logo2} alt='logo2' />
                  </span>
                </div>

              ))}
            </div>
          </Box>

          <Box
            backgroundColor='rgba(149, 149, 149, 0.26)'
            padding={['20px', '20px']}
            border='3px solid #5cb0ee'
            borderRadius='100px'
            minHeight={['45vh', '50vh']}
            alignItems='center'
            display='flex'
            justifyContent='center'
            flexDirection='column'
            width={['100%', '35%']}
            marginBottom={['0px', 0]}>

            <Dropdown
              onSelect={handleSelectCoin1} optionGroups={[
                {
                  groupName: 'Featured Coins',
                  options: [...featuredTokens]
                },
                {
                  groupName: 'Wallet Tokens',
                  options: walletTokenList.map((item: { contract_address: any; contract_ticker_symbol: any; logo_url: any; }) => ({ value: item.contract_address, label: item.contract_ticker_symbol, imageUrl: item.logo_url }))
                },
                {
                  groupName: 'All Crypto',
                  options: cryptoList.map((coin: { symbol: any; uuid: any; iconUrl: any; }) => ({
                    label: coin.symbol,
                    value: coin.uuid,
                    imageUrl: coin.iconUrl
                  }))
                }
              ]} />

            <Text style={{ marginTop: '20px', marginBottom: '20px', color: '#fff', fontSize: '20px', fontWeight: '200' }}>AT THE MARKETCAP OF</Text>

            <Dropdown optionGroups={[
              {
                groupName: 'Featured Coins',
                options: [...featuredTokens]
              },
              {
                groupName: 'All Crypto',
                options: cryptoList.map((coin: { symbol: any; uuid: any; iconUrl: any; }) => ({
                  label: coin.symbol,
                  value: coin.uuid,
                  imageUrl: coin.iconUrl
                }))
              }
            ]}
              onSelect={handleSelectCoin2} />

            <Span5>{result && result.length > 2 && `${result}x`}</Span5>
            <Button
              backgroundColor="#47a1ff"
              marginTop="20px"
              colorScheme="teal"
              onClick={handleCompare}
            >
              Compare
            </Button>
          </Box>
          <Box
            backgroundColor='rgba(149, 149, 149, 0.26)'
            padding='20px'
            border='3px solid #5cb0ee'
            borderRadius='30px'
            alignItems='center'
            justifyContent='center'
            width={['75%', '20%']}
          >
            <Box
              backgroundColor='rgba(149, 149, 149, 0.26)'
              borderRadius='30px'
              display='flex'
              textColor='white'
              justifyContent='center'
              alignItems='center'
            ><Text>WHALES</Text></Box>
            <Text color='white' display='flex' alignSelf='center'  >Coming Soon!</Text>
          </Box>
        </HStack>

        <VStack padding='20px'>

          <Div6>Wen Moon?</Div6>
          <Flex
            justifyContent='center'
            flexDirection='column'
            backgroundColor='rgba(149, 149, 149, 0.26)'
            padding='30px'
            minW={['100%', '517px']}
            border='3px solid #5cb0ee'
            borderRadius='80px'

          >
            <Dropdown options={walletTokenList.map((item: { contract_address: any; contract_ticker_symbol: any; logo_url: any; }) => ({ value: item.contract_address, label: item.contract_ticker_symbol, imageUrl: item.logo_url }))}
              onSelect={handleTokenSelect} />
            <Span5>
              <Button onClick={() => { calculateX(10) }} color='#5cb0ee' fontSize={['20px', '40px']} backgroundColor='transparent'>10x</Button>
              <Button onClick={() => { calculateX(100) }} color='#5cb0ee' fontSize={['20px', '40px']} backgroundColor='transparent'>100x</Button>
              <Button onClick={() => { calculateX(1000) }} color='#5cb0ee' fontSize={['20px', '40px']} backgroundColor='transparent'>1000x</Button>
            </Span5>
            <Text alignSelf='center' color='white'>Balance : {`${balance.toFixed(2)} ~ $${tokenValue.toFixed(2)}`}</Text>
            <Span5>
              <Text>{resultXValue !== 0 && `$${resultXValue.toFixed(2)}`}</Text>
            </Span5>
          </Flex>

          <Flex
            marginTop='20px'
            justifyContent='center'
            flexDirection='column'
            backgroundColor='rgba(149, 149, 149, 0.26)'
            padding='30px'
            border='3px solid #5cb0ee'
            borderRadius='40px'
            minW={['100%', '517px']}
          >

            <Box
              backgroundColor='rgba(149, 149, 149, 0.26)'
              borderRadius='30px'
              display='flex'
              textColor='white'
              justifyContent='center'
              marginBottom='10px'
            ><Text>TOP GAINERS</Text></Box>

            <div className="gainer-box-table">

              {gainerTokens.map((gainer: { name: any; logo: any; price: string; gainPercent: any; }) => (
                <div key={gainer.name} className="gainer-item">
                  <span className="token">  <img src={gainer.logo} alt={`${gainer.name} logo`} />{gainer.name}</span>
                  <span>{parseFloat(gainer.price).toFixed(3)}<span style={{ color: "green" }}> &#x2191; {gainer.gainPercent}</span></span>

                </div>
              ))}
            </div>

          </Flex>

          <Box
            marginTop='20px'
            backgroundColor='rgba(149, 149, 149, 0.26)'
            padding={['20px', '30px']}
            border='3px solid #5cb0ee'
            borderRadius={['40px', '80px']}
            alignItems='center'
            width={['100%', '750px']}>
            <Box
              backgroundColor='rgba(149, 149, 149, 0.26)'
              borderRadius='30px'
              display='flex'
              textColor='white'
              justifyContent='center'
              marginBottom='10px'
            ><Text>Top Market Cap Tokens</Text></Box>

            <div className="top-token-table">
              <div className="top-column">
                {topTokens.slice(0, 5).map((token: { logo: any; name: any; marketCap: any; }) => (
                  <div className="token-item">
                    <span className="token">
                      <img src={token.logo} alt={`${token.name} logo`} />
                      {token.name}
                    </span>
                    <span style={{ color: '#5cb0ee' }}>${token.marketCap}</span>
                  </div>
                ))}
              </div>
              <div className="top-column">
                {topTokens.slice(5, 10).map((token: { logo: any; name: any; marketCap: any; }) => (
                  <div className="token-item">
                    <span className="token">
                      <img src={token.logo} alt={`${token.name} logo`} />
                      {token.name}
                    </span>
                    <span style={{ color: '#5cb0ee' }}>${token.marketCap}</span>
                  </div>
                ))}
              </div>
            </div>

          </Box>

          <SpanBottom>
            <VStack>
              <Img5 loading="lazy" srcSet="./bottom1.png" />
              <Footer>
                <text>
                  &copy; Copyright 2023 Marvin. All Rights Reserved{" "}
                </text>
              </Footer>
              <SimpleGrid gap={5} p={5} columns={2}>
                <IconButton
                  variant="outline"
                  colorScheme="twitter"
                  aria-label="Twitter icon"
                  onClick={() => {
                    window.open("https://twitter.com/moontoolsapp", "_blank");
                  }}
                  icon={<Icon as={FaTwitter} />}
                />


                <IconButton
                  size="xl"
                  variant="outline"
                  colorScheme="telegram"
                  aria-label="WhatsApp icon"
                  onClick={() => {
                    window.open(
                      "https://www.t.me/marvininuofficial",
                      "_blank"
                    );
                  }}
                  icon={<Icon as={FaTelegram} />}
                />
              </SimpleGrid>
            </VStack>
          </SpanBottom>
        </VStack>
      </div>
    </>
  );
};

export default CryptoForm;
