import React, { useState } from 'react';
import './dropdown.css';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

interface Option {
  label: string;
  value: string;
  imageUrl: string;
}

interface OptionGroup {
  groupName: string;
  options: Option[];
}

interface DropdownProps {
  options?: Option[];
  optionGroups?: OptionGroup[]; // Options with groups
  onSelect: (selectedOption: string) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ options, optionGroups, onSelect }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  const handleItemClick = (option: Option) => {
    setSelectedOption(option);
    onSelect(option.value);
    setIsActive(false);
  };

  const renderOptions = () => {
    if (optionGroups && optionGroups.length > 0) {
      return optionGroups.map((group, groupIndex) => (
        <div key={groupIndex} className="group">
          <div className="group-header">{group.groupName}</div>
          {group.options.map((option, index) => (
            <div key={index} className="item" onClick={() => handleItemClick(option)}>
              <span className="token">
                <img src={option.imageUrl} alt="Small circle" />
                {option.label}
              </span>
            </div>
          ))}
        </div>
      ));
    } else if (options && options.length > 0) {
      return options.map((option, index) => (
        <div key={index} className="item" onClick={() => handleItemClick(option)}>
          <span className="token">
            <img src={option.imageUrl} alt="Small circle" />
            {option.label}
          </span>
        </div>
      ));
    } else {
      return <div className="no-results">No options available</div>;
    }
  };

  return (
    <div className="dropdown">
      <div onClick={() => setIsActive(!isActive)} className="dropdown-btn">
        {selectedOption ? (
          <span className="token">
            <img src={selectedOption.imageUrl} alt="Small circle" />
            {selectedOption.label}
          </span>
        ) : (
          <span className="placeholder">Select an option</span>
        )}

        {isActive ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </div>
      <div className="dropdown-content" style={{ display: isActive ? 'block' : 'none' }}>
        {renderOptions()}
      </div>
    </div>
  );
};

export default Dropdown;
