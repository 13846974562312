// StyledComponents.tsx
import styled from 'styled-components';

export const Div = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: monospace;
`;

export const Div2 = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: 1024px;
  width: 100%;
  font-family: monospace;
  padding: 6px 20px 13px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Img = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

export const Span = styled.span`
  position: relative;
  color: #fff;
  background-color: #5cb0ee;
  align-self: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 3px 3px;
  margin-top: 10px;
  border-radius : 5px;
  font: 400 70px Francois One, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
    padding: 1.5px 1.5px;
    
  }
`;

export const Div3 = styled.div`
  color: #5cb0ee;
  align-self: center;
  margin-top: 55px;
  font-family: "Francois One", Helvetica;
  font-size: 90px;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 50px;
    margin-top: 40px;
    
  }
`;

export const Div4 = styled.div`
  position: relative;
  color: #fff;
  align-self: center;
  line-height : normal;
  letter-spacing: 0;
  bottom: 40px;
  left:80px;
  margin: 5px 0 0 163px;
  font: 400 30px Francois One, Helvetica;
  @media (max-width: 991px) {
    white-space: initial;
    font-size : 20px;
    bottom : 30px;
    left : 0px;
    margin: 5px 0 0 163px;
  }
`;

export const Span2 = styled.span`
 
  border-radius: 59px;
  border: 3px solid #3e65c9;
  background-color: rgba(149, 149, 149, 0.26);
  align-self: center;
  display: flex;
  margin-top: 0px;
  width: 441px;
  max-width: 90%;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px 10px 20px;
  @media (max-width: 991px) {
    max-width: 90%;
    // Add responsive styles if needed
  }
`;

export const Div6 = styled.div`
  color: #fff;
  white-space: nowrap;
  align-items: center;
  font: 400 25px Francois One, sans-serif;
  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;

export const Span5 = styled.span`
  color: #5cb0ee;
  white-space: nowrap;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.61);
  align-self: center;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  font: 250 55px monospace;
  @media (max-width: 991px) {
    font-size: 20px;
    padding: 0 10px;
  }
`;

export const Img5 = styled.img`
  aspect-ratio: 1.18;
  object-fit: contain;
  object-position: center;
  width: 271px;
  overflow: hidden;
  align-self: center;
  margin-top: 11px;
  max-width: 100%;
`;

export const SpanBottom = styled.span`
  position: relative;
  color: #fff;
  background-color: transparent;
  align-self: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 10px;
  }
`;
 
export const Footer = styled.span`{
  position: relative;
  color: #fff;
  background-color: transparent;
  align-self: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 10px;
  }
}`